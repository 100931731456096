import { ReactNode } from "react";
import tw, { css } from "twin.macro";

const GetInvolvedListItem = ({
	number,
	children,
}: {
	number: number;
	children: ReactNode;
}) => {
	return (
		<div tw="grid grid-template-columns[30px 1fr] pb-7 lg:(pb-4)">
			<div tw="font-sticker text-light-beige text-sm flex justify-center items-center">
				<span tw="bg-orange rounded-full transform[translateY(-5px)] lg:transform[translateY(0px)] width[30px] height[30px] text-center pt-1">
					{number.toString()}
				</span>
			</div>
			<div
				css={[
					tw`flex items-center  ml-4 -mt-3 lg:(mt-0) font-medium`,
					css`
						a {
							text-decoration: underline;
						}
					`,
				]}
			>
				<span tw="translate-y-0.5">{children}</span>
			</div>
		</div>
	);
};

export default GetInvolvedListItem;
