import tw from "twin.macro";

const DoubleBorderDiv = ({
	children,
	warmTheme = false,
}: {
	children: JSX.Element;
	warmTheme?: boolean;
}) => {
	return (
		<div
			css={[
				tw` border-width[1px] p-2 w-full`,
				warmTheme
					? tw`bg-bitters border-bourbon`
					: tw`bg-light-beige border-bitters`,
			]}
		>
			<div
				css={[
					tw`border-width[1px] p-3`,
					warmTheme ? tw`border-bourbon` : tw`border-bitters`,
				]}
			>
				{children}
			</div>
		</div>
	);
};

export default DoubleBorderDiv;
